/* ======================================================================== */
/* 47. splitText */
/* ======================================================================== */
.split-text, .split-chars
	opacity: 0
	visibility: hidden
.split-text__line
	// display: inline !important
	overflow: hidden
.split-chars__line
	display: inline
	overflow: hidden
