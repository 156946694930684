/* ======================================================================== */
/* 30. sectionComposition */
/* ======================================================================== */
.section-composition
	display: flex
.section-composition__image
	display: flex
	align-items: center
	justify-content: center
	padding: 200px 120px
	flex: 1 1 auto
.section-composition__content
	display: flex
	align-items: flex-end
	flex-wrap: wrap
	width: 100%
	max-width: 640px
	flex: 1 0 33vw
	padding: 200px 120px
	h6
		font-size: 20px
.section-composition__counter
	align-self: flex-start
	font-family: $font-secondary
	font-size: 16px
	font-weight: 500
	line-height: 1.5
	letter-spacing: 1px
	text-transform: uppercase
	margin-bottom: 20px

@media screen and (max-width: $xxl)
	.section-composition__image, .section-composition__content
		padding: 120px 80px
	.section-composition__counter
		font-size: 12px

@media screen and (max-width: $xl)
	.section-composition__image, .section-composition__content
		padding: 80px 50px
		
@media screen and (max-width: $md)
	.section-composition
		flex-direction: column
		justify-content: center
	.section-composition__content
		// text-align: center
		align-items: center
		justify-content: center
		max-width: 100%
		flex: 1 0 100%
		order: -1
		padding: 80px 30px 0
		background-color: initial
		// .figure-property
		// 	width: auto
	.section-composition__image
		background-color: initial
	.section-composition__counter
		align-self: unset
		margin-left: auto
		margin-right: auto
		width: 100%
		margin-bottom: 20px
	.section-composition__image
		padding: 30px 30px 80px
	.section-composition__content h6
		font-size: 16px
