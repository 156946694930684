/* ======================================================================== */
/* 6. comments */
/* ======================================================================== */
.comments-area, .comment-respond
	h2
		font-size: 24px
		font-weight: 600
		line-height: 1.5
		margin-top: 0
		margin-bottom: 10px
.comment-list
	+reset-ul
	li
		padding-top: 30px
		padding-bottom: 30px
	> li:not(:last-child)
	ol
		+reset-ul
		padding-left: 4%
		li:last-child
			padding-bottom: 0
