/* ======================================================================== */
/* 33. sectionFullscreenSlider */
/* ======================================================================== */
.section-fullscreen-slider.color-white
	.slider__dot
		&:before
			background-color: $brown-grey
		&:after
			border-color: $white
	.slider__dot_active
		&:before
			background-color: $white
			border-color: $white

.section-fullscreen-slider__inner
	padding-top: 0
	padding-bottom: 0

// @media screen and (max-width: $xxl)
// 	.section-fullscreen-slider__inner
// 		padding-top: 50px
// 		padding-bottom: 50px

// @media screen and (max-width: $xl)
// 	.section-fullscreen-slider__inner
// 		padding-top: 30px
// 		padding-bottom: 30px

// @media screen and (max-width: $sm)
// 	.section-fullscreen-slider__inner
// 		padding-top: 0
// 		padding-bottom: 0
