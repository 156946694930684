/* ======================================================================== */
/* 4. counter */
/* ======================================================================== */
.counter
	text-align: center
.counter__number
	font-size: 68px
	font-weight: 200
	line-height: 1
.counter__label
	margin-top: 5px

// @media only screen and (max-width: $xl)
// 	.counter__number
@media screen and (max-width: $xxl)
  .counter__number
    font-size: 54px
@media screen and (max-width: $md)
  .counter__number
    font-size: 43px
