/* ======================================================================== */
/* 36. sectionMasthead */
/* ======================================================================== */
.section-masthead__header
	max-width: 960px
	h1
		margin-top: 1.75em
		margin-bottom: 0.5em
	h2
		margin-top: 100px
.section-masthead_fullheight
	h1
		margin-top: 0
		margin-bottom: 0.5em
.section-masthead_fullheight-halfscreen
	.section-masthead__background
		position: relative
		min-height: 100vh
.section-masthead_fullheight-halfscreen-reverse
	flex-direction: row-reverse
.section-masthead__wrapper-property
	margin-right: 70px
	margin-bottom: 70px
	&:last-of-type
		margin-right: 0
.section-masthead__properties
	margin-top: 70px
	margin-bottom: -70px
.section-masthead__background_bottom
	margin-top: 100px
.section-masthead__background_fullscreen
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	margin-top: 0
.section-masthead__overlay
	z-index: 0
	opacity: .6
.section-masthead__properties_bottom
	position: absolute
	bottom: 70px
	left: 120px
.section-masthead__content
	width: 100%
.section-masthead__inner
	position: relative
	z-index: 50

@media screen and(max-width: $xxl)
	.section-masthead__properties_bottom
		left: 80px
		bottom: 50px

@media screen and (max-width: $xl)
	.section-masthead__wrapper-property
		margin-right: 30px
	.section-masthead__properties_bottom
		left: 50px
	.section-masthead__background_bottom
		margin-top: 80px


@media screen and (max-width: $md)
	.section-masthead__wrapper-property
		margin-right: 0
	.section-masthead__wrapper-property
		margin-bottom: 30px
	.section-masthead__properties
		margin-top: 30px
		margin-bottom: -30px
	.section-masthead__properties_bottom
		position: relative
		left: auto
		bottom: auto
	.section-masthead_fullheight-halfscreen
		padding-top: 100px
		flex-wrap: wrap
		.section-fullheight__inner
			min-height: unset
			padding-top: 0
			padding-bottom: 0
		.section-masthead__background
			margin-top: 80px
	.section-masthead_fullheight
		h1
			margin-top: 1.75em
			margin-bottom: 0.5em
	.section-masthead__background_bottom
		width: 100%
		margin-top: 80px


@media screen and (max-width: $sm)
	.section-masthead__header
		h1
			margin-top: 40px
		h2
			margin-top: 30px
			font-size: 21px
	.section-masthead_fullheight-halfscreen
		padding-top: 80px
		.section-masthead__background
			min-height: 0
			height: 900px
			max-height: 70vh
			margin-top: 60px
	.section-masthead__background_bottom
		margin-top: 60px
