html
	font-size: $em-base
	-moz-osx-font-smoothing: grayscale
	-webkit-font-smoothing: antialiased
	text-rendering: optimizeLegibility
	overflow-x: hidden
	width: 100%
	height: 100%
body
	position: relative
	width: 100%
	// height: 100%
	min-width: 320px
	font-family: $font-primary
	background-color: $light-blue-grey-2
	color: $black
	font-size: 100%
	line-height: 1.75
	overflow-x: hidden
.body_lock-scroll
	overflow-y: hidden
	position: fixed
	top: 0
	right: 0
	bottom: 0
	left: 0
	// height: 100%
.body_custom-cursor
	cursor: none
img
	max-width: 100%
	height: auto
.container-fluid
	padding-left: 120px
	padding-right: 120px
.container
	padding-left: 25px
	padding-right: 25px
.row
	margin-left: -25px
	margin-right: -25px
// .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto
//   padding-left: 25px
//   padding-right: 25px
.col, [class*='col-']
	padding-left: 25px
	padding-right: 25px
.no-gutters
	margin-left: 0 !important
	margin-right: 0 !important
.page-wrapper
	z-index: 50
	// background-color: $light-blue-grey
	
@media screen and (max-width: $xxl)
	.container-fluid
		padding-left: 80px
		padding-right: 80px
@media screen and (max-width: $xl)
	.container-fluid
		padding-left: 50px
		padding-right: 50px
@media screen and (max-width: $md)
	.container-fluid, .container
		padding-left: 30px
		padding-right: 30px
	.row
		margin-left: -15px
		margin-right: -15px
	.col, [class*='col-']
		padding-left: 15px
		padding-right: 15px
