/* ======================================================================== */
/* 35. sectionIntro */
/* ======================================================================== */
.section-intro.text-center
	.section-intro__header, .section-intro__headline
		margin-left: auto
		margin-right: auto
.section-intro__header
	max-width: 1200px
	h1
		margin-top: 2em
		margin-bottom: 1em
		font-size: 43px
		line-height: 1.67
.section-intro__headline
	width: 100%
	max-width: 120px
	height: 1px
	background-color: $black

@media screen and (max-width: $xl)
	.section-intro__header
		h1
			font-size: 33px
	.section-intro__headline
		max-width: 80px
@media screen and (max-width: $md)
	.section-intro__header
		h1
			font-size: 24px
@media screen and (max-width: $sm)
	.section-intro__header
		h1
			margin-top: 40px
			font-size: 21px
	.section-intro__headline
		max-width: 50px
