/* ======================================================================== */
/* 5. cursor */
/* ======================================================================== */
.cursor
	position: fixed
	user-select: none
	pointer-events: none
	z-index: 10000
	display: none
.cursor__follower
	position: relative
	width: 60px
	height: 60px
	border-radius: 100%
	// border: 1px solid $brown-grey
	// outline: 1px solid $brown-grey
	overflow: hidden
	svg
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		// width: 100%
		// height: 100%
		#inner, #outter
			fill: none
			stroke-linecap: butt
		#inner
			stroke-width: 2px
			stroke: $brown-grey-2
			opacity: .6
		#outter
			stroke-width: 4px
			stroke: $light-blue-grey-2
			// stroke: red
