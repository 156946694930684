/* ======================================================================== */
/* 41. sliderFullscreen */
/* ======================================================================== */
.slider-halfscreen_fullscreen
	height: 100vh
	flex-direction: column
	.slider-halfscreen__images
		width: 100%
		height: 100%
	.slider-halfscreen__content
		position: absolute
		top: 50%
		transform: translateY(-50%)
		width: 100%
		height: auto
	.slider-halfscreen__content-inner
		padding: 0 30px
		text-align: center
		h2
			margin-top: 0
			margin-bottom: 0
			width: 100%
	.slider-halfscreen__wrapper-link
		margin-top: 40px
		bottom: auto
		left: auto
		position: relative
	.slider-halfscreen__link
		display: inline-flex
	.slider-halfscreen__header p
		margin-left: auto
		margin-right: auto
