/* ======================================================================== */
/* 22. menuOverlay */
/* ======================================================================== */
.menu-overlay
	position: relative
	+reset-ul
	width: 100%
	max-width: 700px
	a
		background-image: none
		transition: opacity 0.6 ease
		&.selected
			opacity: 1 !important
	&:hover
		> li
			> a
				color: $white
				opacity: .2
	> li
		display: block
		> a
			display: block
			font-size: 54px
			line-height: 2
			font-weight: 600
			color: $white
			span
				display: inline-block
				position: relative
		&:hover
			> a
				color: $white
				opacity: 1
	> li:not(.menu-item-has-children)
		> a
			span
				display: inline-block
				position: relative
			&:hover
				color: $white

.menu-overlay .sub-menu
	display: inline-flex
	flex-direction: column
	// justify-content: flex-end
	position: absolute
	top: 0
	left: 0
	height: 100%
	width: 100%
	+reset-ul
	z-index: 100
	&:hover
		> li
			> a
				color: $white
				opacity: .2
	> li
		display: block
		> a
			display: block
			position: relative
			font-size: 33px
			line-height: 2.25
			font-weight: 600
			// overflow: hidden
			color: $white
			span
				position: relative
				display: inline-block
			.menu-overlay__item-wrapper
		&:hover
			> a
				color: $white
				opacity: 1
	> li:not(.menu-item-has-children)
		> a
			&:hover
				color: $white

@media screen and (max-width: $xxl)
	.menu-overlay
		> li
			> a
				font-size: 43px
				line-height: 2
	.menu-overlay .sub-menu
		> li
			> a
				font-size: 26px
				line-height: 2.33

@media screen and (max-width: $md)
	.menu-overlay
		max-width: 100%
		> li
			> a
				font-size: 24px
				line-height: 2.5
	.menu-overlay .sub-menu
		> li
			> a
				font-size: 19px
				line-height: 2.33
