/* ======================================================================== */
/* 17. header */
/* ======================================================================== */
.header
	position: absolute
	top: 0
	left: 0
	right: 0
	z-index: 500
	padding-top: 70px
.header_white
	.logo
		color: $white
		&:focus
			color: $white
	.header__burger-line
		background-color: $white
.header_fixed
	position: fixed
.header__burger
	position: relative
	display: flex
	flex-direction: column
	align-items: flex-end
	justify-content: center
	width: 40px
	height: 40px
	cursor: pointer
	z-index: 600
	&:not(.header__burger_opened):hover
		.header__burger-line
			&:nth-child(1)
				transform: scaleX(0.75)
			&:nth-child(2)
				transform: scaleX(1.25)
.header__burger_opened
	.header__burger-line
		background-color: $white
		&:nth-child(1)
			transform: scaleX(0.75)
		&:nth-child(2)
			transform: scaleX(1.25)
	&:hover
		.header__burger-line
			&:nth-child(1)
				transform: scaleX(1)
			&:nth-child(2)
				transform: scaleX(1)
.header__burger-line
	display: block
	width: 100%
	background-color: $black
	height: 2px
	margin: 4px 0
	+trans2
	transform-origin: right center
	&:nth-child(1)
		max-width: 40px
	&:nth-child(2)
		max-width: 30px
.header__wrapper-overlay-menu
	display: flex
	// flex-wrap: wrap
	position: fixed
	top: 0
	left: 0
	right: 0
	bottom: 0
	z-index: 500
	overflow-x: hidden
	overflow-y: auto
	-webkit-overflow-scrolling: touch
	opacity: 0
	visibility: hidden
.header__wrapper-menu
	display: flex
	align-items: flex-end
	padding: 120px 120px 40px
	width: 100%
	// flex: 1 0 auto
.header__wrapper-overlay-widgets
	display: flex
	flex-direction: column
	justify-content: flex-end
	// width: 100%
	// max-width: 500px
	// flex: 1 0 500px
	flex: 1 1 auto
	padding: 120px 120px 60px
	// display: none
.header__wrapper-property
	margin-bottom: 50px
	font-size: 16px
	&:last-of-type
		margin-bottom: 0
.header__overlay-menu-back
	position: absolute
	top: 70px
	left: 110px
	font-size: 45px !important
	color: $white
	z-index: 600
	cursor: pointer
.header__col-left
	position: relative
	// z-index: -2
	// z-index: -1
.header__col-right
	
@media screen and (max-width: $xxl)
	.header
		padding-top: 50px
	.header__wrapper-menu
		padding: 80px 80px 40px
	.header__wrapper-overlay-widgets
		padding: 80px 80px 60px
	.header__overlay-menu-back
		top: 50px
		left: 70px

@media screen and (max-width: $xl)
	.header
		padding-top: 40px
	.header__wrapper-menu
		padding: 50px 50px 30px
	.header__wrapper-overlay-widgets
		padding: 50px 50px 40px
	.header__wrapper-property
		margin-bottom: 40px
	.header__overlay-menu-back
		top: 40px
		left: 40px

@media screen and (max-width: $md)
	.header__burger-line
		margin: 3px 0
		&:nth-child(1)
			max-width: 30px
		&:nth-child(2)
			max-width: 20px
	.header__wrapper-menu
		padding: 0
		width: 100%
		padding: 50px 0
	.header__wrapper-overlay-menu
		flex-wrap: wrap
		text-align: center
		padding: 50px 30px
	.header__wrapper-overlay-widgets
		border-top: 1px solid $borders-light
		background-color: initial
		padding: 50px 0
	.header__overlay-menu-back
		top: 25px
		left: 25px
		font-size: 33px !important
@media screen and (max-width: $md)
	.header
		padding-top: 20px
	.header_fixed
