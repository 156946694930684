/* ======================================================================== */
/* 49. utilities */
/* ======================================================================== */
.text-left
	text-align: left !important
	.section__headline
		margin-right: auto
.text-center
	text-align: center !important
	.section__headline
		margin-left: auto
		margin-right: auto
.text-right
	text-align: right !important
	.section__headline
		margin-left: auto
.line
.row-80
	margin-left: -40px
	margin-right: -40px
.overflow
	position: relative
	overflow: hidden
.overflow__curtain
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	z-index: 50
.overflow__content
	width: 100%
	height: 100%
.position-relative
	position: relative
.color-white
	color: $white !important
.color-black
	color: $black0 !important
.color-dark
	color: $dark !important
.blurred
	filter: blur(7px)
.of-cover
	width: 100%
	height: 100%
	object-fit: cover
	font-family: 'object-fit: cover;'
.of-contain
	width: 100%
	height: 100%
	object-fit: contain
	font-family: 'object-fit: contain;'
.split-line
	overflow: hidden
.split-word
.split-char
.hidden
	opacity: 0
	visibility: hidden
.no-gutters
	padding-left: 0
	padding-right: 0
.grayscale
	filter: grayscale(70%)
.h-100
	height: 100%
.lockhover
	// pointer-events: none
	.menu-overlay
		> li
			> a:not(.selected)
				opacity: .2
	.menu-overlay .sub-menu
		> li
			> a:not(.selected)
				opacity: .2
