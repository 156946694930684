/* ======================================================================== */
/* 34. sectionImage */
/* ======================================================================== */
.section-image__content
	margin-top: 40px
.section-image
	h5
		display: block
		width: 100%
		margin-top: 1em
		margin-bottom: 0
