/* ======================================================================== */
/* 27. section */
/* ======================================================================== */
$margin-small: 100px
$margin-normal: 200px
$margin-large: 300px

@mixin setMargins($m-small, $m-normal, $m-large)
	.section_pt-large
		padding-top: $m-large
	.section_pt
		padding-top: $m-normal
	.section_pt-small
		padding-top: $m-small
	.section_pb
		padding-bottom: $m-normal
	.section_pb-small
		padding-bottom: $m-small
	.section_pb-large
		padding-bottom: $m-large
	.section_mt-large
		margin-top: $m-large
	.section_mt
		margin-top: $m-normal
	.section_mt-small
		margin-top: $m-small
	.section_mb
		margin-bottom: $m-normal
	.section_mb-small
		margin-bottom: $m-small
	.section_mb-large
		margin-bottom: $m-large

.section
	position: relative
.section-fullheight
	display: flex
.section-fullheight__inner
	position: relative
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
	width: 100%
	min-height: 100vh
	padding-top: $margin-normal
	padding-bottom: $margin-normal

@include setMargins($margin-small, $margin-normal, $margin-large)

.section__header
	h2
		margin-bottom: 0.75em
	h3
		&:last-of-type
			margin-bottom: 0
.section__headline
	display: block
	width: 80px
	height: 1px
	background-color: $dark
	margin-bottom: 10px
	// vertical-align: top
@for $i from 1 through 9
	.section_h-#{$i * 100}
		height: #{$i * 100}px
		max-height: 100vh
.section_h-100vh
	height: 100vh
.section__content
	margin-top: 50px
.section_w-container-right
	max-width: 100%
	width: calc( 100% - ( (100vw - 1140px) / 2 ) - 25px )
	margin-left: auto
	text-align: left
.section_w-container-left
	max-width: 100%
	width: calc( 100% - ( (100vw - 1140px) / 2 ) - 25px )
	margin-right: auto
	text-align: right

@media screen and (max-width: $xl)

	$margin-small: 70px
	$margin-normal: 140px
	$margin-large: 240px
	@include setMargins($margin-small, $margin-normal, $margin-large)

	.section-fullheight__inner
		padding-top: $margin-normal
		padding-bottom: $margin-normal
	.section__headline
		max-width: 80px
	.section_w-container-right, .section_w-container-left
		width: 100%
		text-align: center

@media screen and (max-width: $md)

	$margin-small: 50px
	$margin-normal: 100px
	$margin-large: 120px
	@include setMargins($margin-small, $margin-normal, $margin-large)

	.section-fullheight__inner
		padding-top: $margin-large
		padding-bottom: $margin-large
	.section__content
		margin-top: 30px

@media screen and (max-width: $sm)

	$margin-small: 40px
	$margin-normal: 80px
	$margin-large: 100px
	@include setMargins($margin-small, $margin-normal, $margin-large)

	.section-fullheight__inner
		padding-top: $margin-normal
		padding-bottom: $margin-normal
	.section__headline
		max-width: 50px

	@for $i from 1 through 9
		.section_h-#{$i * 100}
			max-height: 70vh
